import React from 'react';
import { ProjectCard } from './Cards/ProjectCard';
import { AboutSection } from './Containers/AboutSection';
import { BlogSection } from './Containers/BlogSection';
import { ParticlesSection } from './Containers/ParticlesSection';
import { SliderSection } from './Containers/SliderSection';
import { TheFooter } from './Globals/TheFooter';
import { TheHeader } from './Globals/TheHeader';
import Projects from './Store/Projects';

const App: React.FC = () => {
  return (
    <div>
      <TheHeader />

      <ParticlesSection>
        <h1>Ty Corcoran</h1>
        <p>Power Platform Architect</p>
        <p>JavaScript &amp; C# enthusiast</p>
      </ParticlesSection>

      <main>
        <AboutSection id="about" />

        <SliderSection id="projects" heading="Projects">
          {Projects.map((pj) => {
            return (
              <ProjectCard
                key={pj.heading}
                heading={pj.heading}
                description={pj.description}
                tech={pj.tech}
                learnMoreHref={pj.learnMoreHref}
                githubHref={pj.githubHref}
                hasDemo={pj.hasDemo}
              />
            );
          })}
        </SliderSection>

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <aside>
            For enhancement requests or bug fixes please create an issue within
            the project's Github repo. I will get back to you as soon as
            possible. Contributors are always welcome.
          </aside>
        </div>

        <BlogSection id="blog" heading="Blog" />
      </main>

      <TheFooter />
    </div>
  );
};

export default App;
