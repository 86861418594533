import styled from 'styled-components';

export const ProjectCard = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  border: 2px solid var(--accent-color-mint-loud);
  border-radius: 5px;
  margin: 20px;
  box-shadow: 0 5px 20px -10px #000;
  min-width: 190px;
  min-height: 300px;
  padding: 15px;
  @media screen and (max-width: 900px) {
    padding: 10px;
    margin: 10px;
  }
`;
