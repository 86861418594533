import React from 'react';
import './styles.css';

interface ButtonProps {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  type:
    | 'delta'
    | 'epsilon'
    | 'eta'
    | 'theta'
    | 'iota'
    | 'kappa'
    | 'mu'
    | 'nu'
    | 'pi'
    | 'tau'
    | 'upsilon'
    | 'phi'
    | 'chi'
    | 'psi'
    | 'omega';
}

export const Button: React.FC<ButtonProps> = (props) => {
  return (
    <button onClick={props.onClick} className={`${props.type} btn`}>
      {props.children}
    </button>
  );
};
