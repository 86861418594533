import React from 'react';
import { FooterItem } from './FooterItem';
import { ReactIcon } from '../Icons';

export const TheFooter: React.FC = (props) => {
  return (
    <footer>
      <FooterItem>&copy; {new Date().getFullYear()} Ty Corcoran</FooterItem>
      <FooterItem>
        Built with{' '}
        <ReactIcon width={15} color="#000" style={{ padding: '0 10px' }} />
      </FooterItem>
    </footer>
  );
};
