import styled from 'styled-components';

export const BlogSection = styled.section`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const BlogSectionColumn = styled.div`
  width: 50%;
  @media screen and (max-width: 1000px) {
    width: 100%;
  }
`;
