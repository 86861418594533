import styled from 'styled-components';

export const ParticlesSection = styled.section`
  background-color: var(--font-color-dark);
  color: var(--font-color-light);
  min-height: 500px;
  width: 100%;
  box-shadow: rgb(0 0 0) 0px 5px 20px -10px;
  padding-bottom: 0;
  @media screen and (max-width: 600px) {
    height: 100vh;
  }
`;

export const ParticlesTextOverlay = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: inherit;
  min-height: inherit;
`;
