const projects = [
  {
    heading: 'Custom CRM for Churches',
    description:
      'CRM built for church leadership to manage contacts, events, announcements, documents and meeting minutes',
    tech: ['csharp', 'react', 'typescript'],
    githubHref: 'https://github.com/tcorcor1/custom-church-crm',
    learnMoreHref: 'https://github.com/tcorcor1/custom-church-crm',
    hasDemo: true,
  },
  {
    heading: 'Toast Notification Framework for D365',
    description:
      'Creating a framework around the in-app/toast notification feature provided by the Dynamics 365 SDK',
    tech: ['csharp', 'dynamics'],
    githubHref: 'https://github.com/tcorcor1/toast-notification-framework-D365',
    learnMoreHref:
      'https://tldr-dynamics.com/blog/toast-notification-framework-dynamics-365',
    hasDemo: true,
  },
  {
    heading: 'Dataverse Analytics',
    description:
      'Creating Dataverse/Dynamics 365 usage analytics dashboards with Azure Application Insights pageView data',
    tech: ['csharp', 'azure', 'dynamics'],
    githubHref: 'https://github.com/tcorcor1/dataverse-analytics',
    learnMoreHref: 'https://tldr-dynamics.com/blog/dataverse-analytics',
    hasDemo: true,
  },
  {
    heading: 'D365 F&O deprecation tracker',
    description:
      'Repo used for tracking changes on various D365 F&O deprecation pages and notifying subscribers',
    tech: ['csharp', 'azure', 'dynamics'],
    githubHref:
      'https://github.com/tcorcor1/d365-finance-ops-deprecation-tracker',
    learnMoreHref:
      'https://tldr-dynamics.com/blog/d365-finance-ops-deprecation-tracker',
    hasDemo: true,
  },
  {
    heading: 'Power Platform deprecation tracker',
    description:
      'Repo used for tracking changes on various Power Platform deprecation pages and notifying subscribers',
    tech: ['csharp', 'azure', 'dynamics'],
    githubHref:
      'https://github.com/tcorcor1/power-platform-deprecation-tracker',
    learnMoreHref:
      'https://tldr-dynamics.com/blog/power-platform-deprecation-tracker',
    hasDemo: true,
  },
  {
    heading: 'NHL Stats CRM',
    description:
      'Scraping the NHL REST endpoint and loading into CRM for no reason',
    tech: ['csharp', 'azure', 'dynamics'],
    githubHref: 'https://github.com/tcorcor1/NhlStatsCrm',
    learnMoreHref: 'https://github.com/tcorcor1/NhlStatsCrm#nhlstatscrm',
    hasDemo: true,
  },
  {
    heading: 'Google Analytics v4 API - Express.js',
    description:
      'Express / TypeScript project designed for interaction with Google Analytics Reporting API v4',
    tech: ['node', 'express', 'typescript'],
    githubHref: 'https://github.com/tcorcor1/google-analytics-express-ts',
    learnMoreHref:
      'https://tldr-dynamics.com/blog/google-analytics-express-typescript',
    hasDemo: false,
  },
  {
    heading: 'Group Approval Viewer',
    description:
      'PCF control designed to create a visualization of Power Automate group approvals and their progression',
    tech: ['react', 'typescript', 'dynamics'],
    githubHref: 'https://github.com/tcorcor1/group-approval-viewer-D365-pcf',
    learnMoreHref: 'https://tldr-dynamics.com/blog/group-approval-viewer-pcf',
    hasDemo: true,
  },
  {
    heading: 'Last Login Module',
    description:
      'Zero-config Vuejs web resource designed to provide administrators and management a quick method to report/measure user engagement of the platform. Includes filtering, export and search',
    tech: ['vue', 'dynamics'],
    githubHref: 'https://github.com/tcorcor1/last-login-mod-D365-vue',
    learnMoreHref: 'https://tldr-dynamics.com/blog/last-login-audit-module',
    hasDemo: true,
  },
  {
    heading: 'Marketing List Importer',
    description: `XrmToolBox plugin for importing lead/contact csv's directly into a D365 marketing list. Includes deduplication, parent account support and logging`,
    tech: ['csharp', 'dynamics'],
    githubHref: 'https://github.com/tcorcor1/MarketingListImporter.TyCorcoran',
    learnMoreHref: 'https://tldr-dynamics.com/blog/marketing-list-importer',
    hasDemo: true,
  },
  {
    heading: 'Field Creator',
    description:
      'XrmToolBox plugin for creating fields by CSV import. Supports all types',
    tech: ['csharp', 'dynamics'],
    githubHref: 'https://github.com/tcorcor1/FieldCreator.TyCorcoran',
    learnMoreHref: 'https://tldr-dynamics.com/blog/field-creator',
    hasDemo: true,
  },
  {
    heading: 'tldr-dynamics.com',
    description:
      'My blog aimed at providing useful information about all topics Power Platform. Also, where all tools are documented',
    tech: ['vue'],
    learnMoreHref: 'https://tldr-dynamics.com/',
    hasDemo: false,
  },
  {
    heading: 'Multipage Modal Dialog',
    description:
      'Vue template providing modern solution to creating a multipage modal dialog in D365',
    tech: ['vue', 'dynamics'],
    githubHref: 'https://github.com/tcorcor1/multipage-modal-D365-vue',
    learnMoreHref:
      'https://tldr-dynamics.com/blog/create-multipage-modal-dialog-vue',
    hasDemo: true,
  },
  {
    heading: 'D365 TypeScript App',
    description:
      'TypeScript boilerplate for D365 client-side scripting. Includes samples and documentation',
    tech: ['typescript', 'javascript', 'dynamics'],
    githubHref: 'https://github.com/tcorcor1/typescript-dataverse-template',
    learnMoreHref:
      'https://tldr-dynamics.com/blog/typescript-dataverse-template',
    hasDemo: false,
  },
  {
    heading: 'D365 Console App',
    description:
      'Boilerplate for interacting with D365 via console app using Office 365 or client-secret authentication',
    tech: ['csharp', 'dynamics'],
    githubHref: 'https://github.com/tcorcor1/console-app-D365',
    learnMoreHref: 'https://tldr-dynamics.com/blog/console-app-sample',
    hasDemo: false,
  },
];

export default projects;
