import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

interface SliderSectionProps {
  id: string;
  heading: string;
  children?: React.ReactNode;
}

export const SliderSection: React.FC<SliderSectionProps> = (props) => {
  return (
    <div id={props.id}>
      <h2># {props.heading}</h2>
      <div style={{ margin: '0 5px' }}>
        <Slider
          infinite={false}
          speed={500}
          slidesToShow={3}
          slidesToScroll={1}
          arrows={true}
          responsive={[
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                arrows: true,
              },
            },
            {
              breakpoint: 900,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true,
              },
            },
          ]}
        >
          {props.children}
        </Slider>
      </div>
    </div>
  );
};
