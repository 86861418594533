import React, { useState } from 'react';
import * as Styled from './styled';
import { BurgerIcon, CloseIcon } from '../Icons';
import { HeaderItem } from './HeaderItem';
import { TheSideMenu } from '../TheSideMenu';

export const TheHeader: React.FC = () => {
  const [sideNavState, setSideNavState] = useState<boolean>(false);

  const sideMenuHandler = (e: React.MouseEvent<HTMLDivElement>) => {
    setSideNavState((prevState) => !prevState);
  };

  return (
    <header>
      <Styled.TheHeader>
        <HeaderItem>
          <h1
            style={{
              border: '3px solid #fff',
              padding: '2.5px 5px',
              borderRadius: '2px',
            }}
          >
            TC
          </h1>
        </HeaderItem>
        <HeaderItem onClick={sideMenuHandler} style={{ zIndex: 999 }}>
          {!sideNavState ? (
            <BurgerIcon color="#FFF" width={50} style={{ display: 'flex' }} />
          ) : (
            <CloseIcon color="#FFF" width={50} style={{ display: 'flex' }} />
          )}
          <TheSideMenu isActive={sideNavState} />
        </HeaderItem>
      </Styled.TheHeader>
    </header>
  );
};
