import styled from 'styled-components';

export const AboutSection = styled.section`
  display: flex;
  @media screen and (max-width: 1000px) {
    display: block;
  }
`;

export const AboutItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  min-width: 50%;
`;
