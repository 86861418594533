import styled from 'styled-components';
import { SideMenuProps } from '.';

export const TheSideMenu = styled.div`
  ${(props: SideMenuProps) =>
    props.isActive ? 'display: flex;' : 'display: hidden;'}
  position: fixed;
  flex-direction: column;
  justify-content: flex-start;
  top: 0;
  right: 0;
  z-index: 200;
  background: var(--accent-color-mint);
  transition: 0.1s;
  border-bottom-left-radius: 750px;
  color: var(--font-color-dark);
  box-shadow: rgb(0 0 0) 0px 5px 20px -10px;
  ${(props: SideMenuProps) =>
    props.isActive ? 'height: 300px;' : 'height: 0;'}
  ${(props: SideMenuProps) => (props.isActive ? 'width: 300px;' : 'width: 0;')}
`;
