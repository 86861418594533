import React from 'react';
import * as Styled from './styled';
import { GithubIcon } from '../../Globals/Icons';
import { getIcon } from '../../Utils';

interface ProjectCardProps {
  heading: string;
  description: string;
  tech: string[];
  learnMoreHref?: string;
  githubHref?: string;
  children?: React.ReactNode;
  hasDemo: boolean;
}

export const ProjectCard: React.FC<ProjectCardProps> = (props) => {
  return (
    <Styled.ProjectCard>
      <h4>{props.heading}</h4>

      <div style={{ display: 'flex' }}>{props.tech.map((i) => getIcon(i))}</div>

      <p>
        {props.description}.{' '}
        <a href={props.learnMoreHref} target="_blank" rel="noreferrer">
          {props.hasDemo ? 'Learn more / demo' : 'Learn more'}
        </a>
      </p>

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {props.githubHref && (
          <GithubIcon width={30} color="#FFF" link={props.githubHref} />
        )}
      </div>
    </Styled.ProjectCard>
  );
};
