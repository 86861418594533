import * as Icon from '../Globals/Icons';

export const debounce = (fxn: () => void) => {
  let timer: NodeJS.Timeout;

  return () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      fxn.apply(this);
    }, 1000);
  };
};

export const getPastDateString = (
  periodType: 'm' | 'd',
  periodLength: number,
): string => {
  const date = new Date();

  if (periodType === 'm') {
    return new Date(
      date.setMonth(date.getMonth() - periodLength),
    ).toLocaleDateString();
  } else {
    return new Date(
      date.setDate(date.getDate() - periodLength),
    ).toLocaleDateString();
  }
};

const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
};

export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`,
};

export const getIcon = (icon: string) => {
  switch (icon) {
    case 'vue':
      return (
        <Icon.VueIcon
          width={30}
          color="#FFF"
          key="vue"
          style={{ paddingRight: '10px' }}
        />
      );
    case 'react':
      return (
        <Icon.ReactIcon
          width={30}
          color="#FFF"
          key="react"
          style={{ paddingRight: '10px' }}
        />
      );
    case 'csharp':
      return (
        <Icon.CSharpIcon
          width={30}
          color="#FFF"
          key="csharp"
          style={{ paddingRight: '10px' }}
        />
      );
    case 'typescript':
      return (
        <Icon.TypeScriptIcon
          width={30}
          color="#FFF"
          key="typescript"
          style={{ paddingRight: '10px' }}
        />
      );
    case 'javascript':
      return (
        <Icon.JavaScriptIcon
          width={30}
          color="#FFF"
          key="javascript"
          style={{ paddingRight: '10px' }}
        />
      );
    case 'dynamics':
      return (
        <Icon.DynamicsIcon
          width={30}
          color="#FFF"
          key="dynamics"
          style={{ paddingRight: '10px' }}
        />
      );
    case 'azure':
      return (
        <Icon.AzureIcon
          width={30}
          color="#FFF"
          key="azure"
          style={{ paddingRight: '10px' }}
        />
      );
    case 'node':
      return (
        <Icon.NodeIcon
          width={30}
          color="#FFF"
          key="azure"
          style={{ paddingRight: '10px' }}
        />
      );
    case 'express':
      return (
        <Icon.ExpressIcon
          width={30}
          color="#FFF"
          key="azure"
          style={{ paddingRight: '10px' }}
        />
      );
  }
};
