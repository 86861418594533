import React from 'react';
import * as Styled from './styled';
import { CloseIcon } from '../Icons';

export interface SideMenuProps {
  isActive?: boolean;
}

export const TheSideMenu: React.FC<SideMenuProps> = (props) => {
  return (
    <Styled.TheSideMenu isActive={props.isActive}>
      <div style={{ display: 'inherit', justifyContent: 'flex-end' }}>
        <CloseIcon width={50} style={{ padding: '10px' }} />
      </div>
      <nav
        style={{
          display: 'inherit',
          flexDirection: 'inherit',
          alignItems: 'flex-end',
          padding: '0 20px 0 0 ',
        }}
      >
        <a href="#about">About</a>
        <a href="#projects">Projects</a>
        <a href="#blog">Blog</a>
      </nav>
    </Styled.TheSideMenu>
  );
};
