import React from 'react';
import { LinkIcon } from '../../Globals/Icons';
import { IconProps } from '../../Globals/Icons';

interface LinkItemProps extends IconProps {
  wrapperStyle?: React.CSSProperties;
  iconStyle?: React.CSSProperties;
  link: string;
}

export const LinkItem: React.FC<LinkItemProps> = (props) => {
  const icoStyles = {
    ...props.iconStyle,
    paddingLeft: '5px',
  };

  const wrapperStyles = {
    ...props.wrapperStyle,
    display: 'flex',
    alignItems: 'center',
  };

  return (
    <div style={{ ...wrapperStyles }}>
      {props.children}{' '}
      <LinkIcon
        width={props.width}
        color={props.color}
        onClick={props.onClick}
        link={props.link}
        style={{ ...icoStyles }}
      />
    </div>
  );
};
