import React from 'react';
import Particles from 'react-tsparticles';
import * as Styled from './styled';
import './styles.css';

interface ParticlesSectionProps {
  children?: React.ReactNode;
}

export const ParticlesSection: React.FC<ParticlesSectionProps> = ({
  children,
}) => {
  return (
    <Styled.ParticlesSection>
      <Styled.ParticlesTextOverlay>{children}</Styled.ParticlesTextOverlay>
      <Particles
        params={{
          particles: {
            color: {
              value: '#09f7d4',
            },
            opacity: {
              value: 0.7,
            },
            links: {
              color: {
                value: '#09f7d4',
              },
              distance: 150,
              enable: true,
              opacity: 0.5,
              width: 1,
            },
            collisions: {
              enable: true,
            },
            move: {
              direction: 'none',
              enable: true,
              outMode: 'bounce',
              random: false,
              speed: 4,
              straight: false,
            },
            number: {
              density: {
                enable: true,
                value_area: 1000,
              },
              value: 50,
            },
          },
        }}
        className="particles-res"
        width="100%"
      />
    </Styled.ParticlesSection>
  );
};
