import React from 'react';
import * as Styled from './styled';

interface HeaderItemProps {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string | undefined;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

export const HeaderItem: React.FC<HeaderItemProps> = (props) => {
  return (
    <Styled.HeaderItem onClick={props.onClick} style={props.style}>
      {props.children}
    </Styled.HeaderItem>
  );
};
