import React, { useState, useEffect } from 'react';
import * as Styled from './styled';
import { LinkItem } from '../LinkItem';
import { Button } from '../../Buttons';

interface BlogSectionProps {
  id: string;
  heading: string;
}

const gaExclusionCollection = [
  'tldr-dynamics | Blog feed',
  'tldr-dynamics',
  'tldr-dynamics | Extending the Power Platform',
  'tldr-dynamics | Resources',
  'tldr-dynamics | Tools',
  'tldr-dynamics | About',
  '(not set)',
];

export const BlogSection: React.FC<BlogSectionProps> = (props) => {
  const [blogPostsState, setBlogPostsState] = useState({
    total: [] as BlogPost[],
  });
  const [randomPost, setRandomPost] = useState<BlogPost>();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/posts`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('GA API Request - Could not retrieve posts');
        }
      })
      .then((result) => {
        const filteredPosts: BlogPost[] = result.rows.filter(
          (post: BlogPost) => {
            const [path, title] = post;
            return !gaExclusionCollection.find((bullshitItem) => {
              return bullshitItem === title;
            });
          },
        );

        setBlogPostsState((prev) => {
          return { ...prev, total: filteredPosts };
        });
      })
      .catch((error) => {
        console.error('GA API Request Error', error);
      });
  }, []);

  const getRandoPost = () => {
    const randoIdx = Math.floor(Math.random() * blogPostsState.total.length);
    setRandomPost((prev) => blogPostsState.total[randoIdx]);
  };

  return (
    <div id={props.id}>
      <h2># {props.heading}</h2>

      <Styled.BlogSection>
        <Styled.BlogSectionColumn>
          <LinkItem width={25} color="#FFF" link="https://tldr-dynamics.com">
            <h4>tldr-dynamics.com</h4>
          </LinkItem>
          <p>
            My blog where I strive to give quality Power Platform content in the
            most clear, concise way I can. I may not always succeed at keeping
            it 'short and sweet' but I will try my best.
          </p>
          <div
            style={{
              display: 'flex',
            }}
          >
            <Button type="chi" onClick={getRandoPost}>
              Get a random post
            </Button>
          </div>
          <div style={{ padding: '20px 0' }}>
            <a
              href={`https://${randomPost?.[0]}`}
              target="_blank"
              rel="noreferrer"
              title={randomPost?.[0]}
            >
              {randomPost?.[1]}
            </a>
          </div>
        </Styled.BlogSectionColumn>

        <Styled.BlogSectionColumn>
          <h3>Most popular</h3>
          <ol>
            {blogPostsState?.total?.slice(0, 7).map((post: BlogPost) => {
              const [path, title, views] = post;
              return (
                <li key={path}>
                  <a
                    href={`https://${path}`}
                    target="_blank"
                    rel="noreferrer"
                    title={title}
                  >
                    {path}
                  </a>
                </li>
              );
            })}
          </ol>
        </Styled.BlogSectionColumn>
      </Styled.BlogSection>
    </div>
  );
};
